<template>
	<div class="upload-modal__inner">
		<!-- Modal header -->
		<div class="upload-modal__header">
			<button
				class="upload-modal__back"
				aria-label="Go back"
				@click="$emit( 'back-to-format-select' )"
			>
				Back
			</button>

			<div class="upload-modal__title-heading">
				<p>
					Project
				</p>
				<h2 class="upload-modal__title">
					{{ project.title }}
				</h2>
			</div>
		</div>

		<!-- Modal content -->
		<div class="upload-modal__content">
			<Transition name="fade">
				<div
					v-if="project.completed"
					class="upload-modal__complete-message"
				>
					<p>
						This project is now complete and further uploading has been disabled.
					</p>
				</div>

				<div
					v-else-if="upload_status === UPLOAD_STATUS_COMPLETE"
					class="upload-modal__complete-message"
				>
					<p>
						Your files are being processed and will be available to view shortly.
					</p>
					<p>
						Remember that uploads need to be approved by a PM or Lead Artist before they are visible to clients.
					</p>
				</div>

				<div
					v-else
					id="image-upload"
					class="upload-modal__drag-drop flex"
					:class="{ 'upload-modal__drag-drop--hover': hover }"
				>
					<table class="upload-modal__files">
						<thead>
							<tr>
								<th>Thumbnail</th>
								<th>Asset</th>
								<th>Tour ID</th>
								<th>Stage</th>
								<th>Artist</th>
								<th />
							</tr>
						</thead>
						<tbody>
							<ExploreUploadRowComponent
								v-for="id, index in entries"
								:ref="id"
								:key="id"
								:id="id"
								:project="project"
								:assets-list="assets_list"
								:staff-list="project_staff_list"
								:can-remove="index > 0"
								:upload-id="upload_id"
								@remove-row="removeExplore( index )"
								@validity-checked="valid => validateEntry( id, valid )"
								@upload-completed="finishEntryUpload( id )"
								@upload-failed="handleUploadFailure( id )"
							/>
						</tbody>

						<tr>
							<td colspan="5">
								<button
									class="button button--fill mt-16"
									:disabled="!awaiting_upload"
									@click="addExplore"
								>
									Add explore
								</button>
							</td>
						</tr>
					</table>
				</div>
			</Transition>
		</div>

		<!-- Modal submit bar -->
		<div class="upload-modal__submit-bar">
			<button
				class="button button--fill"
				:class="{ 'button--loading': upload_in_progress }"
				:disabled="!awaiting_upload"
				@click="checkValidity"
			>
				Upload
			</button>
		</div>
	</div>
</template>

<script>
import ExploreUploadRowComponent from './ExploreUploadRowComponent.vue';

import { useDataStore } from '../../stores/data';
import { storeToRefs } from 'pinia';
import moment from 'moment';
import {
	UPLOAD_STATUS_AWAITING,
	UPLOAD_STATUS_IN_PROGRESS,
	UPLOAD_STATUS_COMPLETE
} from '../../constants';

export default {
    name: 'ExploreUploadComponent',
	components: {
		ExploreUploadRowComponent
	},
    props: {
        show: { required: true, type: Boolean },
        project: { required: true, type: Object }
    },
    emits: ['close-modal', 'back-to-format-select'],
    setup() {
        const data_store = useDataStore();
        const { user_profile } = storeToRefs(data_store);
        const { getStaffUsers } = data_store;
        return {
            user_profile,
            getStaffUsers
        };
    },
    data() {
        return {
			upload_id: null,
            upload_status: UPLOAD_STATUS_AWAITING,
            show_complete_message: false,
            hover: false,
            assets_list: null,
            staff_list: [],
            entries: [ `explore-${ Date.now() }` ],
			validated_entries: [],
			uploaded_entries: [],
            errors: {},
        };
    },
    computed: {
        awaiting_upload() {
            return this.upload_status === UPLOAD_STATUS_AWAITING;
        },
		upload_in_progress() {
			return this.upload_status === UPLOAD_STATUS_IN_PROGRESS;
		},
        project_staff_list() {
            return this.staff_list.filter(user => this.userIsOnProject(user));
        }
    },
	watch: {
		validated_entries: {
			handler() {
				const sorted_entries = this.entries.slice().sort();
				const sorted_validated_entries = this.validated_entries.slice().sort();

				if ( this.compareArrays( sorted_entries, sorted_validated_entries ) ) {
					this.entries.forEach( entry_id => {
						if ( !this.uploaded_entries.includes( entry_id ) ) {
							this.$refs[entry_id][0].submitUpload();
						}
					} );
				}
			}
		}, deep: true,
		uploaded_entries: {
			handler() {
				const sorted_entries = this.entries.slice().sort();
				const sorted_uploaded_entries = this.uploaded_entries.slice().sort();

				if ( this.compareArrays( sorted_entries, sorted_uploaded_entries ) ) {
					this.upload_status = UPLOAD_STATUS_COMPLETE;
					this.show_complete_message = true;
				}
			}
		}, deep: true

	},
    mounted() {
        this.formatAssetsList();
        this.getStaffList();
		this.generateUploadId();
    },
    methods: {
		userIsOnProject( user ) {
            return [
				...this.project.project_artists.map( artist => artist.id ),
				...this.project.lead_artist.map( artist => artist.id )
			].includes( user.value );
        },
        async formatAssetsList() {
            this.assets_list = this.project.project_assets
				.filter( project_asset => project_asset.media_type.length && project_asset.media_type[0].base_type === 'explore' )
				.map( project_asset => {
					return { value: project_asset.id, label: project_asset.title };
				})
			;
        },
        async getStaffList() {
            this.getStaffUsers()
                .then((response) => {
                this.staff_list = response.data.users.map(user => {
                    return { value: user.id, label: user.fullName };
                });
            });
        },
		compareArrays( a, b ) {
			return (
				a.length === b.length &&
				a.every( ( element, index ) => element === b[index] )
			);
		},
        addExplore() {
            this.entries.push( `explore-${ Date.now() }` );
		},
        removeExplore(index) {
            this.entries.splice( index, 1 );
        },
		validateEntry( entry_id, valid ) {
			if ( valid === true ) {
				this.validated_entries.push( entry_id );
				return;
			}
			this.upload_status = UPLOAD_STATUS_AWAITING;
		},
		finishEntryUpload( entry_id ) {
			if ( this.uploaded_entries.indexOf( entry_id ) > -1 ) {
				return;
			}
			this.uploaded_entries.push( entry_id );
		},
		handleUploadFailure() {
			this.upload_status = UPLOAD_STATUS_AWAITING;
		},
        getAsset(asset_id) {
            return this.project.project_assets.find(asset => asset.id === asset_id);
        },
        getError(field_handle) {
            switch (field_handle) {
                case 'tour_id':
                    return 'Please enter a tour ID';
                case 'asset_id':
                    return 'Please select an asset';
                case 'revision_number':
                    return 'Please enter a valid stage (e.g. A1, B12, etc.)';
                case 'artist_id':
                    return 'Please enter an artist';
            }
        },
        generateUploadId() {
            this.upload_id = this.$route.params.project_id + moment(new Date()).format('-YYYYMMDDmmss-') + this.user_profile.uid;
        },
		checkValidity() {
			this.upload_status = UPLOAD_STATUS_IN_PROGRESS;
			this.validated_entries = [];
			this.entries.forEach( entry_id => {
				if ( !this.uploaded_entries.includes( entry_id ) ) {
					this.$refs[entry_id][0].checkValidity();
				}
			} );
		},
        closeModal() {
            this.entries = {};
            this.errors = {};
            this.upload_status = UPLOAD_STATUS_AWAITING;
            this.$emit('close-modal');
        }
    }
};
</script>