import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "checkboxes-dropdown__toggle-label" }
const _hoisted_2 = { class: "checkboxes-dropdown__content" }
const _hoisted_3 = ["id", "value", "placeholder"]
const _hoisted_4 = { class: "checkboxes-dropdown__options-list" }
const _hoisted_5 = { class: "checkbox-wrapper" }
const _hoisted_6 = ["value", "checked", "onChange"]
const _hoisted_7 = { class: "checkbox__label" }
const _hoisted_8 = { key: 0 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "target",
    class: _normalizeClass(["checkboxes-dropdown", {
			'checkboxes-dropdown--expand': $setup.show_dropdown,
			'checkboxes-dropdown--stretch': $props.stretch
		}])
  }, [
    _createElementVNode("button", {
      class: "checkboxes-dropdown__toggle",
      onClick: _cache[0] || (_cache[0] = $event => ($setup.show_dropdown = !$setup.show_dropdown))
    }, [
      _createElementVNode("p", _hoisted_1, _toDisplayString($props.label), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (!$props.searchDisabled)
        ? (_openBlock(), _createElementBlock("input", {
            key: 0,
            id: $props.id,
            class: "checkboxes-dropdown__search",
            type: "text",
            value: $props.query,
            placeholder: $props.placeholder,
            onInput: $setup.handleSearch
          }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_3))
        : _createCommentVNode("v-if", true),
      _createElementVNode("ul", _hoisted_4, [
        ($props.loading)
          ? (_openBlock(), _createBlock($setup["PageLoaderComponent"], {
              key: 0,
              smaller: true
            }))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (option) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: `dropdown-option-${option.value}`
                }, [
                  _createElementVNode("label", _hoisted_5, [
                    _createElementVNode("input", {
                      class: "checkbox",
                      type: "checkbox",
                      value: option.value,
                      checked: $props.selection.includes( option.value ),
                      onChange: e => $setup.handleSelection( e, option )
                    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_6),
                    _createElementVNode("span", _hoisted_7, _toDisplayString(option.label), 1 /* TEXT */)
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */)),
              (!$props.options || $props.options.length === 0)
                ? (_openBlock(), _createElementBlock("li", _hoisted_8, " No results "))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
      ])
    ])
  ], 2 /* CLASS */))
}