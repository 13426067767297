import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "search-bar" }
const _hoisted_2 = { class: "search-bar__count" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.active_projects_tab !== 'example_projects')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          ($setup.user_is_staff && $setup.active_projects_tab === 'all')
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: _normalizeClass(["search-bar__filters-toggle", $setup.show_filters ? 'search-bar__filters-toggle--toggled': 'search-bar__filters-toggle--untoggled']),
                onClick: _cache[0] || (_cache[0] = $event => ($setup.show_filters = !$setup.show_filters))
              }, _toDisplayString($setup.show_filters ? 'Close filter' : 'Filter'), 3 /* TEXT, CLASS */))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", {
            class: "search-bar__field a4-12",
            onClick: $setup.focusProjectSearch
          }, [
            _withDirectives(_createElementVNode("input", {
              ref: "project_search_input",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.project_search_query) = $event)),
              class: "search__input",
              type: "text",
              placeholder: "Search for a project",
              onInput: $setup.handleProjectSearchInputChange
            }, null, 544 /* HYDRATE_EVENTS, NEED_PATCH */), [
              [_vModelText, $setup.project_search_query]
            ])
          ])
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("p", _hoisted_2, " Showing " + _toDisplayString($setup.project_pagination.total_count) + " project" + _toDisplayString($setup.project_pagination.total_count === 1 ? '' : 's'), 1 /* TEXT */)
  ]))
}