import moment from 'moment';

export function getWindowHeight() {
	return ( window.innerHeight || document.documentElement.clientHeight );
}

export function getWindowWidth() {
	return ( window.innerWidth || document.documentElement.clientWidth );
}

export function updatePageTitle( page_title ) {
	document.title = 'TBL – ' + page_title;
}

// Source: https://gist.github.com/lanqy/5193417
export function convertBytesToSize( bytes ) {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if ( bytes === 0 ) {
		return 'n/a';
	}
	const i = parseInt( Math.floor( Math.log( bytes ) / Math.log( 1024 ) ), 10 );
	if ( i === 0 ) {
		return `${ bytes } ${ sizes[i] } )`;
	}
	return `${ ( bytes / ( 1024 ** i ) ).toFixed( 1 ) } ${ sizes[i] }`;
}

export function filterOutDuplicates( data ) {
	const unique_ids = [];

	return data.filter( item => {
		if ( unique_ids.includes( item.id ) ) {
			return false;
		}
		unique_ids.push( item.id );
		return true;
	} );
}

export function getTimePassed( date_string ) {
	const moment_created = moment( date_string, 'YYYY-MM-DD HH:mm:ss.SSSSSS' );
	const time = moment_created.valueOf();
	const now = new Date().getTime();
	const difference_in_ms = now - time;
	const minutes = Math.floor( difference_in_ms / 60000 );

	if ( minutes > 10080 ) {
		return moment_created.format( "D MMM YY" );
	}
	if ( minutes > 1440 ) {
		return ( Math.floor( minutes / 1440 ) ) + 'd ago';
	}
	if ( minutes > 60 ) {
		return ( Math.floor( minutes / 60 ) ) + 'h ago';
	}
	if ( minutes < 1 ) {
		return ( minutes * 60 ) + 's ago';
	}
	return minutes + 'm ago';
}

export function validateRevisionNumber( string ) {
	const pattern = /^[aAbB][0-9]+$/;
	return pattern.test( string );
}

export function getAssetThumbnailOption( asset ) {
	if ( !asset.asset_revisions || !asset.asset_revisions.length ) {
		return null;
	}

	// Grab asset's revisions, sort by revision number and grab latest one (or final revision if present)
	let latest_revision;

	if ( asset.final_revision && asset.final_revision.length ) {
		latest_revision = asset.asset_revisions
			.find( revision => revision.id === asset.final_revision[0].id )
		;
	} else {
		const sorted_revisions = asset.asset_revisions
			.slice()
			.filter( revision => revision.revision_options.length )
			.sort( ( a, b ) => {
				const a_first = a.revision_number.length > 0 ? a.revision_number[0] : 0;
  				const b_first = b.revision_number.length > 0 ? b.revision_number[0] : 0;

				if ( a_first < b_first ) {
					return -1;
				}
				if ( a_first > b_first ) {
					return 1;
				}

				const a_remaining = a.revision_number.length > 0 ? a.revision_number.slice( 1 ) : 0;
  				const b_remaining = b.revision_number.length > 0 ? b.revision_number.slice( 1 ) : 0;

				return parseInt( a_remaining ) - parseInt( b_remaining );
			} )
		;
		latest_revision = sorted_revisions.at( -1 );
	}

	if ( !latest_revision ) {
		return null;
	}

	// Sort options by option number and return first one if image url is present
	const sorted_options = latest_revision.revision_options
		.slice()
		.filter( option => option.image_small_url || option.thumbnail_url )
		.sort( ( a, b ) => a.title > b.title ? 1 : -1 )
	;

	const client_approved_option = sorted_options.find( option => option.client_approval_user && option.client_approval_user.length > 0 );
	if ( client_approved_option ) {
		return client_approved_option;
	}

	const latest_option = sorted_options[0];

	if ( !latest_option || ( !latest_option.image_small_url && !latest_option.thumbnail_url ) ) {
		return null;
	}

	return latest_option;
}

export function getProjectThumbnailOption( project ) {
	// Grab assets with contentful revisions
	const assets_with_revisions = project.project_assets
		.filter( asset => asset.asset_revisions.length && asset.asset_revisions.find( revision => revision.revision_options.length ) )
	;
	if ( !assets_with_revisions.length ) {
		return null;
	}

	// Sort assets by revision date if present - if not, assume last revision was a long time ago
	assets_with_revisions
		.sort( ( a, b ) => new Date( b.last_revision_date || '2000-01-01' ) - new Date( a.last_revision_date || '2000-01-01' ) )
	;

	let latest_asset = assets_with_revisions[0];

	if ( project.project_thumbnail.length ) {
		const thumbnail_asset = assets_with_revisions.find( asset => asset.id === project.project_thumbnail[0].id );

		if ( thumbnail_asset ) {
			latest_asset = thumbnail_asset;
		}
	}

	return getAssetThumbnailOption( latest_asset );
}
