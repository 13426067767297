<template>
	<div
		ref="target"
		class="checkboxes-dropdown"
		:class="{
			'checkboxes-dropdown--expand': show_dropdown,
			'checkboxes-dropdown--stretch': stretch
		}"
	>
		<button
			class="checkboxes-dropdown__toggle"
			@click="show_dropdown = !show_dropdown"
		>
			<p class="checkboxes-dropdown__toggle-label">
				{{ label }}
			</p>
		</button>
		<div class="checkboxes-dropdown__content">
			<input
				v-if="!searchDisabled"
				:id="id"
				class="checkboxes-dropdown__search"
				type="text"
				:value="query"
				:placeholder="placeholder"
				@input="handleSearch"
			>
			<ul class="checkboxes-dropdown__options-list">
				<PageLoaderComponent
					v-if="loading"
					:smaller="true"
				/>

				<template v-else>
					<li
						v-for="option in options"
						:key="`dropdown-option-${option.value}`"
					>
						<label class="checkbox-wrapper">
							<input
								class="checkbox"
								type="checkbox"
								:value="option.value"
								:checked="selection.includes( option.value )"
								@change="e => handleSelection( e, option )"
							>
							<span class="checkbox__label">{{ option.label }}</span>
						</label>
					</li>
					<li v-if="!options || options.length === 0">
						No results
					</li>
				</template>
			</ul>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';
import PageLoaderComponent from './PageLoaderComponent.vue';

const emit = defineEmits( ['search', 'select'] );

defineProps( {
	id: {
		required: true,
		type: String
	},
	label: {
		required: true,
		type: String
	},
	placeholder: {
		required: false,
		type: String,
		default: 'Search'
	},
	query: {
		required: false,
		type: String,
		default: ''
	},
	options: {
		required: true,
		type: Array
	},
	selection: {
		required: true,
		type: Array
	},
	stretch: {
		required: false,
		type: Boolean,
		default: false
	},
	searchDisabled: {
		required: false,
		type: Boolean,
		default: false
	},
	loading: {
		required: false,
		type: Boolean,
		default: false
	}
} );

const target = ref( null );
const show_dropdown = ref( false );

onClickOutside( target, () => show_dropdown.value = false );

const handleSearch = ( e ) => {
	emit( 'search', e.target.value );
};
const handleSelection = ( e, option ) => {
	emit( 'select', {
		value: option.value,
		checked: e.target.checked,
		label: option.label
	} );
};
</script>
