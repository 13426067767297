<template>
	<div class="upload-modal__inner">
		<!-- Modal header -->
		<div class="upload-modal__header">
			<button
				class="upload-modal__back"
				aria-label="Go back"
				@click="$emit( 'back-to-format-select' )"
			>
				Back
			</button>

			<div class="upload-modal__title-heading">
				<p>
					Project
				</p>
				<h2 class="upload-modal__title">
					{{ project.title }}
				</h2>
			</div>

			<div
				v-if="awaiting_upload"
				class="upload-modal__submission-select"
			>
				<div
					class="form__set form__set--no-margin"
					:class="{ 'form__set--error': errors['submission_id'] }"
				>
					<label
						for="submission"
						class="form__label"
					>
						Submission
						<button
							class="upload-modal__add-submission-button"
							@click="show_submission_modal = true"
						>
							(Create submission)
						</button>
					</label>
					<div class="form__icon form__icon--down">
						<select
							v-model="submission_id"
							class="form__select"
						>
							<option
								v-for="submission in submissions_list"
								:key="`submission-${ submission }`"
								:value="submission.value"
							>
								{{ submission.label }}
							</option>
							<option
								v-if="submissions_list && submissions_list.length"
								disabled
							>
								–––––––––
							</option>
							<option value="none">
								No submission
							</option>
						</select>
					</div>
				</div>

				<div
					v-if="show_submission_modal"
					class="upload-modal__create-submission-modal"
				>
					<form @submit.prevent="createNewSubmission">
						<h3 class="upload-modal__create-submission-title">
							Create submission
						</h3>
						<div class="form__set">
							<label
								class="form__label"
								for="submission-name"
							>
								Submission name
							</label>
							<input
								id="submission-name"
								v-model="new_submission_form.name"
								class="form__input"
								type="text"
								required
							>
						</div>
						<div class="form__set mb-64">
							<label
								class="form__label"
								for="submission-comment"
							>
								Comment
							</label>
							<textarea
								id="submission-comment"
								v-model="new_submission_form.comment"
								class="form__input"
								rows="2"
							/>
						</div>

						<div class="flex flex--justify-end flex--gap-small">
							<button
								class="button"
								@click="cancelSubmissionCreation"
							>
								Cancel
							</button>
							<button
								class="button button--fill"
								type="submit"
							>
								Create
							</button>
						</div>
					</form>
				</div>
			</div>

			<div
				class="upload-modal__progress-wrapper"
				:class="{ 'invisible': awaiting_upload }"
			>
				<div class="upload-modal__upload-status" />
				<div class="upload-modal__progress-bar" />
			</div>
		</div>

		<!-- Modal content -->
		<div class="upload-modal__content upload-modal__content--video">
			<Transition name="fade">
				<div
					v-if="project.completed"
					class="upload-modal__complete-message"
				>
					<p>
						This project is now complete and further uploading has been disabled.
					</p>
				</div>
				<div
					v-else-if="show_complete_message"
					class="upload-modal__complete-message"
				>
					<p>
						Your files are being processed and will be available to view shortly.
					</p>
					<p>
						Remember that uploads need to be approved by a PM or Lead Artist before they are visible to clients.
					</p>
				</div>

				<div
					v-else
					id="image-upload"
					class="upload-modal__drag-drop flex"
					:class="{ 'upload-modal__drag-drop--hover': hover }"
				>
					<div
						v-if="errors['submission_id']"
						class="upload-modal__errors"
					>
						<div class="form__error">
							<p>
								{{ errors['submission_id'] }}
							</p>
						</div>
					</div>
					<drag-drop
						v-if="uppy && awaiting_upload"
						:uppy="uppy"
						:props="uppy_config"
					/>
					<table
						v-if="Object.keys( files ).length > 0"
						class="upload-modal__files"
					>
						<thead>
							<tr>
								<th>Filename</th>
								<th>Asset</th>
								<th>Stage</th>
								<th>Option</th>
								<th>Artist</th>
							</tr>
						</thead>
						<tbody>
							<template
								v-for="( file, file_id) in files"
								:key="file_id"
							>
								<tr>
									<td>
										{{ file.filename }}
									</td>

									<td>
										<template v-if="!awaiting_upload">
											{{ assets_list.find( asset => asset.value === file.asset_id ).label }}
										</template>
										<div
											v-else
											class="form__set form__set--no-margin"
											:class="{ 'form__set--error': errors[ file_id ] && errors[ file_id ].asset_id }"
										>
											<div class="form__icon form__icon--down">
												<select
													v-model="file.asset_id"
													class="form__select form__select--stretch"
												>
													<option
														v-for="project_asset in assets_list"
														:key="`asset-${ file.filename + '-' + project_asset.value }`"
														:value="project_asset.value"
													>
														{{ project_asset.label }}
													</option>
												</select>
											</div>
										</div>
									</td>

									<td>
										<template v-if="!awaiting_upload">
											{{ file.revision }}
										</template>
										<div
											v-else
											class="form__set form__set--no-margin"
											:class="{ 'form__set--error': errors[ file_id ] && errors[ file_id ].revision }"
										>
											<input
												v-model="file.revision"
												class="form__input form__input--short"
												type="text"
											>
										</div>
									</td>

									<td>
										<template v-if="!awaiting_upload">
											{{ file.option }}
										</template>
										<div
											v-else
											class="form__set form__set--no-margin"
											:class="{ 'form__set--error': errors[ file_id ] && errors[ file_id ].option }"
										>
											<div class="form__icon form__icon--down">
												<select
													v-model="file.option"
													class="form__select form-select--stretch"
												>
													<option
														v-for="number in [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ]"
														:key="`option-${ number }`"
													>
														{{ number }}
													</option>
												</select>
											</div>
										</div>
									</td>

									<td>
										<template v-if="!awaiting_upload">
											{{ staff_list.find( artist => artist.value === file.artist ).label }}
										</template>
										<div
											v-else
											class="form__icon form__icon--down"
										>
											<select
												v-model="file.artist"
												class="form__select form__select--stretch"
												:class="{ 'form__select--error': errors[ file_id ] && errors[ file_id ].artist }"
												:disabled="!project_staff_list || !project_staff_list.length"
											>
												<option
													v-for="user in project_staff_list"
													:key="`submission-${ user.value }`"
													:value="user.value"
												>
													{{ user.label }}
												</option>
												<option
													v-if="!project_staff_list || !project_staff_list.length"
													value=""
												>
													No artists on project
												</option>
											</select>
										</div>
									</td>

									<td>
										<button
											v-if="awaiting_upload"
											class="upload-modal__remove-file"
											@click="removeFile( file_id )"
										/>
									</td>
								</tr>
								<tr v-if="errors[ file_id ]">
									<td colspan="4">
										<div class="upload-modal__errors">
											<div
												v-for="( error, error_id ) in errors[ file_id ]"
												:key="`error-${ error_id }`"
												class="form__error"
											>
												<p>
													{{ error }}
												</p>
											</div>
										</div>
									</td>
								</tr>
							</template>
						</tbody>
					</table>
				</div>
			</Transition>
		</div>

		<!-- Modal submit bar -->
		<div
			v-if="Object.keys( files ).length > 0"
			class="upload-modal__submit-bar"
		>
			<p>
				{{ getTotalFileSize( uppy_files ) }}
			</p>
			<button
				class="button button--fill"
				:disabled="!awaiting_upload"
				@click="submitUpload"
			>
				Upload
			</button>
		</div>
	</div>
</template>

<script>
import Uppy from '@uppy/core';
import Transloadit from '@uppy/transloadit';
import ProgressBar from '@uppy/progress-bar';
import StatusBar from '@uppy/status-bar';
import '@uppy/progress-bar/dist/style.css';
import '@uppy/status-bar/dist/style.css';

import { DragDrop } from '@uppy/vue';

import moment from 'moment';
import { storeToRefs } from 'pinia';

import { useDataStore } from '../../stores/data';
import { convertBytesToSize, validateRevisionNumber } from '../../../helpers';

import {
	UPLOAD_STATUS_AWAITING,
	UPLOAD_STATUS_IN_PROGRESS,
	UPLOAD_STATUS_COMPLETE
} from '../../constants';

export default {
	name: 'VideoUploadComponent',
	components: {
		DragDrop
	},
	inject: [
		'tl_key',
		'tl_video_template_id'
	],
	props: {
		show: { required: true, type: Boolean },
		project: { required: true, type: Object }
	},
	emits: [ 'close-modal', 'back-to-format-select' ],
	setup() {
		const data_store = useDataStore();
		const { user_profile } = storeToRefs( data_store );
		const {
			getSubmissionsByProjectId,
			createSubmission,
			getStaffUsers
		} = data_store;
		return {
			user_profile,
			getSubmissionsByProjectId,
			createSubmission,
			getStaffUsers
		};
	},
	data() {
		return {
			upload_status: UPLOAD_STATUS_AWAITING,
			show_complete_message: false,
			hover: false,
			upload_id: '',
			uppy: null,
			uppy_config: {
				height: '100%',
				width: '100%',
				note: 'Filename convention example', // Filename example set in /sass/vendor/uppy-drag-drop as pseudo element
				locale: {
					strings: {
						poweredBy: 'The Boundary Live',
						dropHereOr: 'Select a file or drag drop here%{browse}',
						browse: 'Browse'
					}
				},
				onDragOver() {
					this.hover = true;
				},
				onDragLeave() {
					this.hover = false;
				}
			},
			files: {},
			errors: {},
			expiry_time: null,
			signature: null,
			submission_id: null,
			assets_list: null,
			show_submission_modal: false,
			submissions_list: [],
			new_submission_form: {
				name: '',
				comment: ''
			},
			staff_list: []
		};
	},
	computed: {
		project_staff_list() {
			return this.staff_list.filter( user => this.userIsOnProject( user ) );
		},
		uppy_params() {
			return {
				auth: {
					key: this.tl_key,
					expires: this.expiry_time
				},
				template_id: this.tl_video_template_id
			};
		},
		uppy_files() {
			if ( !this.uppy ) {
				return [];
			}
			return this.uppy.getFiles();
		},
		awaiting_upload() {
			return this.upload_status === UPLOAD_STATUS_AWAITING;
		},
		assembly_options() {
			return ( file ) => {
				return {
					fields: {
						submission_id: this.submission_id,
						filename: this.files[file.id].filename,
						asset_id: this.files[file.id].asset_id,
						revision: this.files[file.id].revision,
						option: this.files[file.id].option,
						artist: this.files[file.id].artist,
						uid: this.user_profile.uid,
						upload_id: this.upload_id
					},
					waitForEncoding: false,
					params: this.uppy_params,
					signature: `sha1:${this.signature}`
				};
			};
		}
	},
	mounted() {
		this.formatAssetsList();
		this.getSubmissionsList();
		this.getStaffList();
		this.getSigningString();
	},
	methods: {
		async formatAssetsList() {
			this.assets_list = this.project.project_assets.map( project_asset => {
				return { value: project_asset.id, label: project_asset.title };
			} );
		},
		async getSubmissionsList() {
			this.getSubmissionsByProjectId( { project_id: this.project.id } )
				.then( ( response ) => {
					this.submissions_list = response.data.entries.map( entry => {
						return { value: entry.id, label: entry.title };
					} );
				} );
		},
		async getSigningString() {
			this.expiry_time = moment.utc().add( 2, 'h' ).format( 'YYYY/MM/DD HH:mm:ss+00:00' );
			try {
				const response = await this.$axios.post( '/upload/sign', {
					'params': JSON.stringify( this.uppy_params )
				} );
				this.signature = response.data;
				this.instantiateUppy();
			} catch ( error ) {
				// console.log( error );
			}
		},
		async getStaffList() {
			this.getStaffUsers()
				.then( ( response ) => {
					this.staff_list = response.data.users.map( user => {
						return { value: user.id, label: user.fullName };
					} );
				} );
		},
		userIsOnProject( user ) {
			return [
				...this.project.project_artists.map( artist => artist.id ),
				...this.project.lead_artist.map( artist => artist.id )
			].includes( user.value );
		},
		createNewSubmission() {
			this.createSubmission( {
				project_id: parseInt( this.project.id ),
				comment: this.new_submission_form.comment,
				title: this.new_submission_form.name
			} ).then( ( response ) => {
				this.submissions_list.push( {
					value: response.data.save_submissions_default_Entry.id,
					label: response.data.save_submissions_default_Entry.title
				} );
				this.submission_id = response.data.save_submissions_default_Entry.id;
				this.cancelSubmissionCreation();
			} );
		},
		cancelSubmissionCreation() {
			this.show_submission_modal = false;
			this.new_submission_form = { name: '', comment: '' };
		},
		instantiateUppy() {
			this.uppy = new Uppy( {
				onBeforeFileAdded: this.addFile,
				restrictions: {
					allowedFileTypes: [ 'video/*' ]
				}
			} );
			this.uppy.use( Transloadit, {
				getAssemblyOptions: this.assembly_options
			} );
			this.uppy.use( ProgressBar, {
				target: '.upload-modal__progress-bar',
				hideAfterFinish: false
			} );
			this.uppy.use( StatusBar, {
				target: '.upload-modal__upload-status',
				hideAfterFinish: false,
				showProgressDetails: false,
				hideUploadButton: true,
				hideRetryButton: true,
				hidePauseResumeButton: true,
				hideCancelButton: true,
				locale: {
					strings: {
						upload: 'Uploading',
						complete: 'Upload completed'
					}
				},
			} );
			this.uppy.on( 'file-removed', ( file ) => {
				if ( file.id in this.files ) {
					delete this.files[file.id];
				}
			} );
			this.uppy.on( 'complete', () => {
				setTimeout( () => {
					this.show_complete_message = true;
				}, 2000 );
			} );
		},
		addFile( new_file ) {
			const existing_files = this.files;
			existing_files[new_file.id] = {
				filename: new_file.meta.name,
				asset_id: this.getAssetId( new_file.meta.name ),
				revision: this.extractRevisionFromFilename( new_file.meta.name ),
				option: this.extractOptionFromFilename( new_file.meta.name ),
				artist: this.getUploadingArtist()
			};
			this.files = Object.keys( existing_files )
				.sort( ( a, b ) => existing_files[a].filename > existing_files[b].filename ? 1 : -1 )
				.reduce(
					( obj, key ) => {
						obj[key] = existing_files[key];
						return obj;
					},
					{}
				);
		},
		removeFile( file_id ) {
			this.uppy.removeFile( file_id );
		},
		getAssetId( filename ) {
			return this.extractAssetIdFromFilename( filename );
		},
		extractAssetIdFromFilename( filename ) {
			const filename_array = filename.split( '_' );
			if ( !filename_array.length ) {
				return '';
			}

			let asset_name = filename_array[0];
			let found_asset = this.assets_list.find( asset => asset.label.replace( '_', ' ' ).toUpperCase() === asset_name.toUpperCase() );
			if ( !found_asset ) {
				asset_name = filename_array[0] + ' ' + filename_array[1];
				found_asset = this.assets_list.find( asset => asset.label.replace( '_', ' ' ).toUpperCase() === asset_name.toUpperCase() );
			}
			return found_asset ? found_asset.value || '' : '';
		},
		extractRevisionFromFilename( filename ) {
			const filename_array = filename.split( '_' );
			if ( !filename_array.length ) {
				return '';
			}

			const revision_regex = /^[abAB]\d{1,2}$/;
			const last_element = filename_array.at( -1 ).split( '.' )[0];
			const second_to_last_element = filename_array.at( -2 );

			if ( revision_regex.test( last_element ) ) {
				return last_element;
			}
			return second_to_last_element;
		},
		extractOptionFromFilename( filename ) {
			const revision_regex = /^[abAB]\d{1,2}$/;
			const last_element = filename.split( '_' ).at( -1 ).split( '.' )[0];

			if ( revision_regex.test( last_element ) ) {
				return 1;
			}
			return last_element.replace( /\D/g, '' ) ? last_element.replace( /\D/g, '' ) : 1;
		},
		getUploadingArtist() {
			if ( this.project_staff_list.find( user => user.value === this.user_profile.id ) ) {
				return this.user_profile.id;
			}
			if ( this.project_staff_list.length ) {
				return this.project_staff_list[0].id;
			}
			return '';
		},
		getTotalFileSize( files ) {
			const total_bytes = files
				.map( file => parseInt( file.size ) )
				.reduce( ( prev, cur ) => prev + cur, 0 );

			return convertBytesToSize( total_bytes );
		},
		generateUploadId() {
			this.upload_id = this.$route.params.project_id +  moment( new Date() ).format( '-YYYYMMDDmmss-' ) + this.user_profile.uid;
		},
		getError( field_handle ) {
			switch ( field_handle ) {
				case 'asset_id':
					return 'Please select an asset';
				case 'revision':
					return 'Please enter a valid stage (e.g. A1, B12, etc.)';
				case 'artist':
					return 'Please enter an artist';
			}
		},
		checkValidity() {
			let invalid = false;
			if ( this.submission_id === null ) {
				invalid = true;
				this.errors['submission_id'] = 'Please select or create a submission';
			}
			for ( const id in this.files ) {
				const empty_fields = Object.entries( this.files[id] ).filter( field => field[0] !== 'option' && !field[1] );
				if ( empty_fields.length ) {
					invalid = true;
					empty_fields.forEach( field => {
						if ( !this.errors[id] ) {
							this.errors[id] = {};
						}
						this.errors[id][field[0]] = this.getError( field[0] );
					} );
				}
				if ( !validateRevisionNumber( this.files[id]['revision'] ) ) {
					invalid = true;
					if ( !this.errors[id] ) {
						this.errors[id] = {};
					}
					this.errors[id]['revision'] = this.getError( 'revision' );
				}
			}
			if ( invalid ) {
				return false;
			}
			return true;
		},
		submitUpload() {
			this.errors = {};
			if ( !this.checkValidity() ) {
				return;
			}
			this.upload_status = UPLOAD_STATUS_IN_PROGRESS;
			this.uppy.upload().then( () => {
				this.upload_status = UPLOAD_STATUS_COMPLETE;
			} );
		},
		closeModal() {
			this.uppy.cancelAll( { reason: 'user' } );
			this.files = {};
			this.errors = {};
			this.upload_status = UPLOAD_STATUS_AWAITING;
			this.$emit( 'close-modal' );
		}
	}
};
</script>