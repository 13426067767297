<template>
	<CheckboxesDropdownComponent
		id="location"
		label="Location"
		placeholder="Search"
		:query="project_location.search_query"
		:options="locations"
		:selection="active_project_location_ids"
		:stretch="true"
		:loading="loading_locations"
		@search="handleProjectLocationSearchInputChange"
		@select="handleLocationSelect"
	/>
</template>

<script setup>
import CheckboxesDropdownComponent from '../CheckboxesDropdownComponent.vue';

import { ref, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const filters_store = useProjectFiltersStore();

const {
	getProjectLocations,
	getCommonProjectLocations,
	setActiveFilterValue,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters
} = filters_store;

const { project_location } = storeToRefs( filters_store );

// Locations
const loading_locations = ref( false );
const show_common_locations = ref( true );

const locations = computed( () => {
	return show_common_locations.value ? project_location.value.common_locations : project_location.value.locations;
} );

const active_project_location_ids = computed( () => {
	return project_location.value.active.map( location => location.value );
} );

const handleLocationSelect = ( location ) => {
	location.checked ? setActiveFilterValue( location, 'project_location' ) : removeActiveFilterValue( location, 'project_location' );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};

const handleProjectLocationSearchInputChange = async ( value ) => {
	setProjectLocationSearchQuery( value );
	const input = value;
	loading_locations.value = true;

	if ( !project_location.value.search_query ) {
		await getCommonProjectLocations();
		show_common_locations.value = true;
		loading_locations.value = false;
		return;
	}
	setTimeout( async() => {
		if ( project_location.value.search_query === input ) {
			await getProjectLocations();
			show_common_locations.value = false;
			loading_locations.value = false;
		}
	}, 500 );
};

// Mount
onMounted( async () => {
	await getCommonProjectLocations();

	loading_locations.value = false;
} );
</script>