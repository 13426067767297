
import { defineStore } from 'pinia';
import { useDataStore } from './data.js';
import { router } from '../router.js';

import gql_query_project_tags from '../graphql/query/ProjectTags.gql';
import gql_query_common_project_tags from '../graphql/query/CommonProjectTags.gql';
import gql_query_project_locations from '../graphql/query/ProjectLocations.gql';
import gql_query_common_project_locations from '../graphql/query/CommonProjectLocations.gql';
import gql_query_companies from '../graphql/query/Companies.gql';
import gql_query_media_types from '../graphql/query/MediaTypes.gql';

import AccountDirectorFilterComponent from '../components/project-filters/AccountDirectorFilterComponent';
import BusinessDevelopmentFilterComponent from '../components/project-filters/BusinessDevelopmentFilterComponent';
import ClientFilterComponent from '../components/project-filters/ClientFilterComponent';
import DeliverablesFilterComponent from '../components/project-filters/DeliverablesFilterComponent.vue';
import LeadArtistFilterComponent from '../components/project-filters/LeadArtistFilterComponent';
import ProjectLocationFilterComponent from '../components/project-filters/ProjectLocationFilterComponent.vue';
import ProjectManagerFilterComponent from '../components/project-filters/ProjectManagerFilterComponent';
import ProjectStatusFilterComponent from '../components/project-filters/ProjectStatusFilterComponent.vue';

const formatDropdownFilterOptions = ( data, options ) => {
	const label_key = options && options.label_key ? options.label_key : 'title';
	const value_key = options && options.value_key ? options.value_key : 'id';
	return data.map( element => {
		return {
			label: element[label_key],
			value: element[value_key]
		}
	} );
};

const normaliseString = ( string ) => {
	return string.normalize( 'NFD' ).replace( /[\u0300-\u036f]/g, '' );
}

const filterUsersByQuery = ( users, query ) => {
	const normalised_query = normaliseString( query.toLowerCase() );
	return users.filter( user => {
		const normalised_user_name = normaliseString( user.label.toLowerCase() );
		return normalised_user_name.includes( normalised_query );
	} );
}
const filterCompaniesByQuery = ( companies, query ) => {
	const normalised_query = normaliseString( query.toLowerCase() );
	return companies.filter( company => {
		const normalised_company_name = normaliseString( company.label.toLowerCase() );
		return normalised_company_name.includes( normalised_query );
	} );
}

export const useProjectFiltersStore = defineStore( 'project-filters', { // eslint-disable-line snakecasejs/snakecasejs
	persist: false,
	state: () => {
		return {
			active_projects_tab: 'my_projects',
			show_filters: false,
			dropdown_filter_components: [
				{
					value: 'project_location',
					label: 'Location',
					component: ProjectLocationFilterComponent,
					selected: true
				},
				{
					value: 'project_status',
					label: 'Project Status',
					component: ProjectStatusFilterComponent,
					selected: true
				},
				{
					value: 'deliverables',
					label: 'Deliverables',
					component: DeliverablesFilterComponent,
					selected: false
				},
				{
					value: 'client',
					label: 'Client',
					component: ClientFilterComponent,
					selected: true
				},
				{
					value: 'project_manager',
					label: 'Project Manager',
					component: ProjectManagerFilterComponent,
					selected: false
				},
				{
					value: 'account_director',
					label: 'Account Director',
					component: AccountDirectorFilterComponent,
					selected: true
				},
				{
					value: 'lead_artist',
					label: 'Lead Artist',
					component: LeadArtistFilterComponent,
					selected: true
				},
				{
					value: 'business_development',
					label: 'Business Development',
					component: BusinessDevelopmentFilterComponent,
					selected: false
				}
			],
			project_search_query: '',
			project_pagination: {
				limit: 20,
				page: 1,
				total_count: null
			},
			project_sorting: {
				selected_sort_order: 'title',
				title_sort_order: 'DESC',
				date_sort_order: 'DESC'
			},
			project_status: {
				statuses: [
					{ label: 'Live', value: 'live' },
					{ label: 'Paused', value: 'paused' },
					{ label: 'Completed', value: 'completed' },
				],
				active: []
			},
			deliverables: {
				media_types: [],
				active: []
			},
			project_tags: {
				search_query: '',
				common_tags: [],
				tags: [],
				active: [],
				total_count: null,
			},
			project_location: {
				search_query: '',
				common_locations: [],
				locations: [],
				active: []
			},
			boundary_staff_users: [],
			project_manager: {
				search_query: '',
				active: [],
			},
			account_director: {
				search_query: '',
				active: [],
			},
			lead_artist: {
				search_query: '',
				active: [],
			},
			business_development: {
				search_query: '',
				active: [],
			},
			companies: [],
			client: {
				search_query: '',
				active: [],
			},
			architect: {
				search_query: '',
				active: [],
			},
			landscape_architect: {
				search_query: '',
				active: [],
			},
			interior_design: {
				search_query: '',
				active: [],
			},
		};
	},
	actions: {
		setFilterSearchQuery( value, filter_handle ) {
			this[filter_handle].search_query = value;
		},
		setActiveFilterValue( value, filter_handle ) {
			this[filter_handle].active.push( value )
		},
		setActiveFilterValues( values, filter_handle ) {
			this[filter_handle].active = values;
		},
		removeActiveFilterValue( value, filter_handle ) {
			const index_to_remove = this[filter_handle].active.findIndex( active_value => active_value.value === value.value );
			if ( index_to_remove < 0 ) {
				return;
			}
			this[filter_handle].active.splice( index_to_remove, 1 );
		},
		setProjectSearchQuery( value ) {
			this.project_search_query = value;
		},
		setActiveProjectsTab( slug ) {
			this.active_projects_tab = slug;
		},
		setProjectSorting( data ) {
			this.project_sorting = data;
		},
		setActiveProjectStatus( status ) {
			this.project_status.active.push( status ) ;
		},
		setActiveProjectStatuses( status_slugs ) {
			this.project_status.active = [];
			status_slugs.forEach( slug => {
				const status = this.project_status.statuses.find( status => status.value === slug );
				if ( status ) {
					this.project_status.active.push( status );
				}
			} );
		},
		removeActiveProjectStatus( status ) {
			const index_to_remove = this.project_status.active.findIndex( active_status => active_status.value === status.value );
			if ( index_to_remove < 0 ) {
				return;
			}
			this.project_status.active.splice( index_to_remove, 1 );
		},
		async getBoundaryStaffUsers() {
			const data_store = useDataStore();
			try {
				const response = await data_store.getBoundaryStaffUsers();
				if ( !response || !response.data ) {
					return;
				}
				this.boundary_staff_users = formatDropdownFilterOptions( response.data.users, { label_key: 'fullName' } );
			} catch ( error ) {
				return error;
			}
		},
		async getCompanies() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_companies, { ids: null } );
				if ( !response || !response.data ) {
					return;
				}
				this.companies = formatDropdownFilterOptions( response.data.entries );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveProjectManagers( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.getBoundaryStaffUsers( ids );
				if ( !response || !response.data ) {
					return;
				}
				this.project_manager.active = formatDropdownFilterOptions( response.data.users, { label_key: 'fullName' } );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveAccountDirectors( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.getBoundaryStaffUsers( ids );
				if ( !response || !response.data ) {
					return;
				}
				this.account_director.active = formatDropdownFilterOptions( response.data.users, { label_key: 'fullName' } );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveLeadArtists( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.getBoundaryStaffUsers( ids );
				if ( !response || !response.data ) {
					return;
				}
				this.lead_artist.active = formatDropdownFilterOptions( response.data.users, { label_key: 'fullName' } );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveBusinessDevelopers( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.getBoundaryStaffUsers( ids );
				if ( !response || !response.data ) {
					return;
				}
				this.business_development.active = formatDropdownFilterOptions( response.data.users, { label_key: 'fullName' } );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveClients( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_companies, { ids: ids } );
				if ( !response || !response.data ) {
					return;
				}
				this.client.active = formatDropdownFilterOptions( response.data.entries, { label_key: 'title' } );
			} catch ( error ) {
				return error;
			}
		},
		async getCommonProjectLocations() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_common_project_locations, {} );
				if ( !response || !response.data ) {
					return;
				}
				this.project_location.common_locations = formatDropdownFilterOptions( response.data.globalSet.common_project_locations );
			} catch ( error ) {
				return error;
			}
		},
		async getProjectLocations() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_project_locations, { query: this.project_location.search_query, ids: null } );
				if ( !response || !response.data ) {
					return;
				}
				this.project_location.locations = formatDropdownFilterOptions( response.data.categories );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveProjectLocations( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_project_locations, { query: '', ids: ids } );
				if ( !response || !response.data ) {
					return;
				}
				this.project_location.active = formatDropdownFilterOptions( response.data.categories );
			} catch ( error ) {
				return error;
			}
		},
		async getDeliverables() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_media_types, { ids: null } );
				if ( !response || !response.data ) {
					return;
				}
				this.deliverables.media_types = formatDropdownFilterOptions( response.data.categories );
			} catch ( error ) {
				return error;
			}
		},
		async getActiveDeliverables( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery(  gql_query_media_types, { ids: ids } );
				if ( !response || !response.data ) {
					return;
				}
				this.deliverables.active = formatDropdownFilterOptions( response.data.categories );
			} catch ( error ) {
				return error;
			}
		},
		async getProjectTags() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_project_tags, { search: this.project_tags.search_query, ids: null } );
				if ( !response || !response.data ) {
					return;
				}
				this.project_tags.tags = response.data.tags;
			} catch ( error ) {
				return error;
			}
		},
		async getActiveProjectTags( ids ) {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_project_tags, { search: null, ids: ids } );
				if ( !response || !response.data ) {
					return;
				}
				this.project_tags.active = response.data.tags;
			} catch ( error ) {
				return error;
			}
		},
		async getCommonProjectTags() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_common_project_tags );
				if ( !response || !response.data ) {
					return;
				}
				this.project_tags.common_tags = response.data.globalSet.common_project_tags;
			} catch ( error ) {
				return error;
			}
		},
		async getTotalProjectTagsCount() {
			const data_store = useDataStore();
			try {
				const response = await data_store.authenticatedQuery( gql_query_project_tags, { search: '', ids: null } );
				if ( !response || !response.data ) {
					return;
				}
				this.project_tags.total_count = response.data.tags.length;
			} catch ( error ) {
				return error;
			}
		},
		setActiveProjectTag( tag ) {
			this.project_tags.active.push( tag );
		},
		setActiveProjectTags( tags ) {
			this.project_tags.active = tags;
		},
		removeActiveProjectTag( index ) {
			this.project_tags.active.splice( index, 1 );
		},
		setShowFilters( value ) {
			this.show_filters = value;
		},
		resetProjectPagination() {
			this.project_pagination.page = 1;
		},
		incrementProjectPagination() {
			this.project_pagination.page++;
		},
		updateProjectTotalCount( value ) {
			this.project_pagination.total_count = value;
		},
		updateQueryStringFromProjectFilters() {
			const query = {};
			query.tab = this.active_projects_tab;

			if ( this.active_projects_tab === 'example_projects' ) {
				this.show_filters = false;
				router.push( {
					name: 'projects',
					query
				} );
				return;
			}

			if ( this.project_search_query ) {
				query.search = this.project_search_query;
			}
			if ( this.active_projects_tab === 'my_projects' ) {
				this.show_filters = false;
				router.push( {
					name: 'projects',
					query
				} );
				return;
			}

			if ( this.project_status.active.length ) {
				query.status = this.project_status.active.map( status => status.value );
			}
			if ( this.deliverables.active.length ) {
				query.deliverable = this.deliverables.active.map( deliverable => deliverable.value );
			}
			if ( this.project_tags.active.length ) {
				query.tag = this.project_tags.active.map( tag => tag.id );
			}
			if ( this.project_location.active.length ) {
				query.location = this.project_location.active.map( location => location.value );
			}
			if ( this.project_manager.active.length ) {
				query.project_manager = this.project_manager.active.map( user => user.value );
			}
			if ( this.account_director.active.length ) {
				query.account_director = this.account_director.active.map( user => user.value );
			}
			if ( this.lead_artist.active.length ) {
				query.lead_artist = this.lead_artist.active.map( user => user.value );
			}
			if ( this.business_development.active.length ) {
				query.business_development = this.business_development.active.map( user => user.value );
			}
			if ( this.client.active.length ) {
				query.client = this.client.active.map( client => client.value );
			}

			query.sort = this.project_sorting.selected_sort_order;
			if ( this.project_sorting.selected_sort_order === 'title' ) {
				query.order = this.project_sorting.title_sort_order;
			} else {
				query.order = this.project_sorting.date_sort_order;
			}

			router.push( {
				name: 'projects',
				query
			} );
		},
		removeAllActiveDropdownFilters() {
			this.project_status.active = [];
			this.project_location.active = [];
			this.deliverables.active = [];
			this.project_manager.active = [];
			this.account_director.active = [];
			this.business_development.active = [];
			this.lead_artist.active = [];
			this.client.active = [];
		},
		resetActiveAdvancedFilters() {
			this.project_tags.active = [];
			this.removeAllActiveDropdownFilters();
		},
		setSelectedDropdownFilters( selection ) {
			this.dropdown_filter_components.forEach( filter => {
				filter.selected = selection.includes( filter.value );
			} );
		},
		removeUnselectedFilters() {
			let active_filters_changed = false;
			this.dropdown_filter_components.forEach( filter => {
				if ( !filter.selected ) {
					if ( this[filter.value].active.length ) {
						this[filter.value].active = [];
						active_filters_changed = true;
					}
				}
			} );
			return active_filters_changed;
		},
		selectMissingActiveDropdowns( selection ) {
			this.dropdown_filter_components.forEach( filter => {
				if ( !filter.selected && selection.includes( filter.value ) ) {
					filter.selected = true;
				}
			} );
		}
	},
	getters: {
		selected_dropdown_filter_components: ( state ) => {
			return state.dropdown_filter_components.filter( filter => filter.selected );
		},
		has_active_filters: ( state ) => {
			return (
				state.project_search_query && state.project_search_query !== '' ||
				state.project_tags.active.length > 0 ||
				this.active_dropdown_filters.length > 0
			);
		},
		active_dropdown_filters: ( state ) => {
			return [
				...state.project_status.active,
				...state.deliverables.active,
				...state.project_location.active,
				...state.project_manager.active,
				...state.account_director.active,
				...state.business_development.active,
				...state.lead_artist.active,
				...state.client.active,
			];
		},
		filtered_account_directors: ( state ) => {
			if ( !state.account_director.search_query ) {
				return state.boundary_staff_users;
			}
			return filterUsersByQuery( state.boundary_staff_users, state.account_director.search_query );
		},
		filtered_project_managers: ( state ) => {
			if ( !state.project_manager.search_query ) {
				return state.boundary_staff_users;
			}
			return filterUsersByQuery( state.boundary_staff_users, state.project_manager.search_query );
		},
		filtered_lead_artists: ( state ) => {
			if ( !state.lead_artist.search_query ) {
				return state.boundary_staff_users;
			}
			return filterUsersByQuery( state.boundary_staff_users, state.lead_artist.search_query );
		},
		filtered_business_developers: ( state ) => {
			if ( !state.business_development.search_query ) {
				return state.boundary_staff_users;
			}
			return filterUsersByQuery( state.boundary_staff_users, state.business_development.search_query );
		},
		filtered_clients: ( state ) => {
			if ( !state.client.search_query ) {
				return state.companies;
			}
			return filterCompaniesByQuery( state.companies, state.client.search_query );
		}
	}
} );
