<template>
	<Transition name="fade">
		<PageLoaderComponent v-if="loading" />

		<div v-else-if="active_discussion">
			<button
				class="back-to mb-32"
				@click="clearActiveDiscussion"
			>
				Back to discussions
			</button>

			<DiscussionComponent
				:project-complete="project.completed"
				:discussion="active_discussion"
				:comments="comments_by_discussion_id[ active_discussion.id ]"
				@refresh-comments="refreshData"
			/>
		</div>

		<div v-else>
			<div class="flex flex--justify-space-between mb-24">
				<button
					v-if="!project.completed"
					class="button button--fill button--fill-orange"
					@click="show_discussion_modal = true"
				>
					Start a new discussion
				</button>

				<div class="flex flex--gap-large flex--align-center">
					<p>
						View:
					</p>
					<button
						class="a"
						:class="{'a--no-underline': filter !== null}"
						@click="filter = null"
					>
						All
					</button>
					<button
						v-if="discussions.find( discussion => discussion.type === 'discussion' )"
						class="a"
						:class="{'a--no-underline': filter !== 'discussion'}"
						@click="filter = 'discussion'"
					>
						Discussions
					</button>
					<button
						v-if="discussions.find( discussion => discussion.type === 'asset' )"
						class="a"
						:class="{'a--no-underline': filter !== 'asset'}"
						@click="filter = 'asset'"
					>
						Assets
					</button>
				</div>
			</div>

			<div
				v-if="discussions && discussions.length"
				class="mb-64"
			>
				<DiscussionListComponent
					:discussions="filtered_discussions"
					@set-active-discussion="setActiveDiscussionId"
				/>
			</div>

			<p v-else>
				There aren't any discussions on this project.
			</p>
		</div>
	</Transition>

	<Teleport to="#modal">
		<Transition name="fade">
			<DiscussionModalComponent
				v-if="show_discussion_modal"
				@close-modal="show_discussion_modal = false"
				@discussion-created="loadNewDiscussion"
			/>
		</Transition>
	</Teleport>
</template>

<script>
import DiscussionComponent from './DiscussionComponent.vue';
import DiscussionListComponent from './DiscussionListComponent.vue';
import DiscussionModalComponent from './modals/DiscussionModalComponent.vue';
import PageLoaderComponent from './PageLoaderComponent.vue';

import { useDataStore } from '../stores/data';

export default {
	components: {
		DiscussionComponent,
		DiscussionListComponent,
		DiscussionModalComponent,
		PageLoaderComponent
	},
	props: {
		project: { required: true, type: Object }
	},
	setup() {
		const data_store = useDataStore();
		const { getDiscussionsByProjectId, getCommentsByDiscussionId } = data_store;
		return { getDiscussionsByProjectId, getCommentsByDiscussionId };
	},
	data() {
		return {
			loading: true,
			discussions: null,
			filter: null,
			comments_by_discussion_id: {},
			active_discussion_id: null,
			show_discussion_modal: false
		};
	},
	computed: {
		filtered_discussions() {
			return this.discussions.filter( discussion => !this.filter || discussion.type === this.filter );
		},
		active_discussion() {
			if ( !this.active_discussion_id ) {
				return null;
			}
			return this.discussions.find( discussion => discussion.id === this.active_discussion_id );
		}
	},
	async mounted() {
		this.loading = true;
		await this.getDiscussions();
		if ( this.$route.query.discussion ) {
			this.active_discussion_id = this.$route.query.discussion;
			await this.getCommentsForDiscussion( this.active_discussion_id );
		}
		this.loading = false;
	},
	methods: {
		async getDiscussions() {
			try {
				const response = await this.getDiscussionsByProjectId( parseInt( this.$route.params.project_id ) );
				if ( response.data ) {
					this.discussions = response.data.discussions;
				}
			} catch ( error ) {
				// console.log( error );
			}
		},
		async getCommentsForDiscussion( discussion_id ) {
			try {
				const response = await this.getCommentsByDiscussionId( parseInt( discussion_id ) );
				if ( response.data.entries ) {
					this.comments_by_discussion_id[ discussion_id ] = response.data.entries;
				}
			} catch ( error ) {
				// console.log( error );
			}
		},
		async loadNewDiscussion( discussion ) {
			if ( this.filter === 'asset' ) {
				this.filter = null;
			}
			await this.getDiscussions();
			await this.getCommentsForDiscussion( parseInt( discussion.id ) );
			this.setActiveDiscussionId( discussion.id );
			this.show_discussion_modal = false;
		},
		refreshData( discussion_id ) {
			this.getCommentsForDiscussion( discussion_id );
			this.getDiscussions();
		},
		async setActiveDiscussionId( discussion_id ) {
			this.loading = true;

			this.active_discussion_id = discussion_id;
			await this.getCommentsForDiscussion( this.active_discussion_id );

			this.$router.push( {
				name: 'projects__project',
				params: { project_id: this.$route.params.project_id },
				query: { tab: 'discussion', discussion: discussion_id }
			} );
			this.loading = false;
		},
		clearActiveDiscussion() {
			this.active_discussion_id = null;
			this.$router.push( {
				name: 'projects__project',
				params: { project_id: this.$route.params.project_id },
				query: { tab: 'discussion' }
			} );
		}
	}
};
</script>
