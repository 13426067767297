<template>
	<Transition name="fade">
		<PageLoaderComponent v-if="loading" />

		<div v-else>
			<div
				v-if="boundary_staff.length"
				class="mb-64"
			>
				<div class="mb-32">
					<h2 class="h4">
						The Boundary
					</h2>
				</div>

				<div class="grid grid--xs flex flex--wrap flex--align-start">
					<div
						v-if="account_director || ( !project.completed && ( user_is_staff_admin || user_is_staff_super_admin ) )"
						class="grid__item a4-12 d6-6 mb-32"
					>
						<h3 class="secondary mb-8">Account Director</h3>

						<DirectoryCardComponent
							:user="account_director"
							:user-type="USER_TYPE_ACCOUNT_DIRECTOR"
							@add-users="openAddUserModal"
						/>
					</div>

					<div
						v-if="project_manager || ( !project.completed && ( user_is_staff_admin || user_is_staff_super_admin ) )"
						class="grid__item a4-12 d6-6 mb-32"
					>
						<h3 class="secondary mb-8">Project Manager</h3>

						<DirectoryCardComponent
							:user="project_manager"
							:user-type="USER_TYPE_PROJECT_MANAGER"
							@add-users="openAddUserModal"
						/>
					</div>

					<div
						v-if="lead_artist || ( !project.completed && ( user_is_staff_admin || user_is_staff_super_admin ) )"
						class="grid__item a4-12 d6-6 mb-32"
					>
						<h3 class="secondary mb-8">Lead Artist</h3>

						<DirectoryCardComponent
							:user="lead_artist"
							:user-type="USER_TYPE_LEAD_ARTIST"
							@add-users="openAddUserModal"
						/>
					</div>
				</div>

				<div v-if="project_artists.length || ( !project.completed && ( user_is_staff_admin || user_is_staff_super_admin ) )">
					<h3 class="secondary mb-8">Artists</h3>
					<ul class="grid grid--xs flex flex--wrap">
						<li
							v-for="user in project_artists"
							:key="user.id"
							class="grid__item a4-12 d6-6"
						>
							<DirectoryCardComponent
								:user="user"
								:user-type="USER_TYPE_ARTIST"
								:hide-email="true"
							/>
						</li>

						<li
							v-if="!project.completed && ( user_is_staff_admin || user_is_staff_super_admin )"
							class="grid__item a4-12 d6-6"
						>
							<DirectoryCardComponent
								:user="null"
								:user-type="USER_TYPE_ARTIST"
								@add-users="openAddUserModal"
							/>
						</li>
					</ul>
				</div>
			</div>

			<div
				v-if="clients.length || ( !project.completed && ( user_is_staff_admin || user_is_staff_super_admin ) )"
				class="mb-64"
			>
				<div class="mb-16">
					<h2 class="h4">
						Clients & Partners
					</h2>
				</div>
				<div class="directory-table">
					<table>
						<thead>
							<tr>
								<th>Name</th>
								<th>Position</th>
								<th>Company</th>
								<th>Email</th>
								<th
									v-if="!project.completed && ( user_is_staff_admin || user_is_staff_super_admin )"
								>
									Email Notifications
								</th>
							</tr>
						</thead>

						<tbody>
							<tr
								v-for="user in clients"
								:key="user.id"
								class="directory-table__row"
							>
								<td>
									{{ user.fullName }}
								</td>
								<td>
									{{ user.user_role }}
								</td>
								<td>
									<template v-if="user.company.length">
										{{ user.company[0].title }}
									</template>
								</td>
								<td>
									<a :href="`mailto:${user.email}`">
										{{ user.email }}
									</a>
								</td>
								<td v-if="user_is_staff_admin || user_is_staff_super_admin">
									<div
										v-if="userHasNotificationsOn( user )"
										class="directory-table__notifications-on"
									>
										On
									</div>
									<div
										v-else
										class="directory-table__notifications-off"
									>
										Off
									</div>
								</td>
							</tr>
						</tbody>
					</table>

					<button
						v-if="!project.completed && ( user_is_staff_admin || user_is_staff_super_admin )"
						class=" directory-table__add-user"
						@click="openAddUserModal( USER_TYPE_CLIENT )"
					>
						Add client or partner
					</button>
				</div>
			</div>

			<Teleport to="#modal">
				<Transition name="fade">
					<AddUserModalComponent
						v-if="active_user_type && user_list && show_user_modal"
						:project="project"
						:user-type="active_user_type"
						:user-list="user_list"
						:loading="loading_user_update"
						:errors="errors"
						:error-message="error_message"
						@add-users-to-project="addUsersToProject"
						@close-modal="closeAddUserModal"
					/>
				</Transition>
			</Teleport>
		</div>
	</Transition>
</template>

<script>
import AddUserModalComponent from './modals/AddUserModalComponent.vue';
import DirectoryCardComponent from './DirectoryCardComponent.vue';
import PageLoaderComponent from './PageLoaderComponent.vue';

import { useDataStore } from '../stores/data';
import {
	USER_TYPE_ACCOUNT_DIRECTOR,
	USER_TYPE_PROJECT_MANAGER,
	USER_TYPE_LEAD_ARTIST,
	USER_TYPE_ARTIST,
	USER_TYPE_CLIENT,
} from '../constants';

export default {
	components: {
		DirectoryCardComponent,
		AddUserModalComponent,
		PageLoaderComponent
	},
	props: {
		project: {
			required: true,
			type: Object
		}
	},
	emits: ['update-directory'],
	setup() {
		const data_store = useDataStore();
		const {
			getBoundaryStaffUsers,
			getClientUsers,
			getFreelancerUsers,
			isUserStaffAdmin,
			isUserStaffSuperAdmin,
			saveUsersToProject,
			user_group,
		} = data_store;

		return {
			getBoundaryStaffUsers,
			getClientUsers,
			getFreelancerUsers,
			isUserStaffAdmin,
			isUserStaffSuperAdmin,
			saveUsersToProject,
			user_group,
			USER_TYPE_ACCOUNT_DIRECTOR,
			USER_TYPE_PROJECT_MANAGER,
			USER_TYPE_LEAD_ARTIST,
			USER_TYPE_ARTIST,
			USER_TYPE_CLIENT
		};
	},
	data() {
		return {
			loading: false,
			loading_user_update: false,
			user_is_staff_admin: false,
			user_is_staff_super_admin: false,
			staff_list: [],
			clients_list: [],
			freelancers_list: [],
			active_user_type: null,
			show_user_modal: false,
			errors: {},
			error_message: null
		}
	},
	computed: {
		user_list() {
			if ( !this.active_user_type ) {
				return [];
			}
			if ( this.active_user_type === USER_TYPE_CLIENT ) {
				return this.clients_list.filter( user => user.label );
			}
			if ( this.active_user_type === USER_TYPE_ARTIST ) {
				return [
					...this.staff_list,
					{ value: 0, label: 'Freelancers:' },
					...this.freelancers_list
				].filter( user => user.label );
				;
			}
			return this.staff_list.filter( user => user.label );;
		},
		account_director() {
			if ( !this.project.account_director.length ) {
				return null;
			}
			return this.project.account_director[0];
		},
		project_manager() {
			if ( !this.project.project_manager.length ) {
				return null;
			}
			return this.project.project_manager[0];
		},
		lead_artist() {
			if ( !this.project.lead_artist.length ) {
				return null;
			}
			return this.project.lead_artist[0];
		},
		project_artists() {
			return this.project.project_artists.sort( ( a, b ) => a.fullName > b.fullName ? 1 : -1 );
		},
		clients() {
			return this.project.clients.sort( ( a, b ) => a.fullName > b.fullName ? 1 : -1 );
		},
		boundary_staff() {
			return [
				...this.project.account_director,
				...this.project.project_manager,
				...this.project.lead_artist,
				...this.project.project_artists
			];
		},
	},
	async mounted() {
		this.loading = true;
		this.user_is_staff_admin = await this.isUserStaffAdmin();
		this.user_is_staff_super_admin = await this.isUserStaffSuperAdmin();

		Promise.all( [
			this.getClientsList(),
			this.getStaffList(),
			this.getFreelancersList()
		] )
			.then( () => {
				this.loading = false;
			} )
			.catch( ( error ) => {
				// console.log( error )
				this.loading = false;
			} )
		;
	},
	methods: {
		async getStaffList() {
			this.getBoundaryStaffUsers()
				.then( ( response ) => {
					this.staff_list = response.data.users.map( user => {
						return { value: user.id, label: user.fullName };
					} );
				} )
			;
		},
		async getClientsList() {
			this.getClientUsers()
				.then( ( response ) => {
					this.clients_list = response.data.users.map( user => {
						return { value: user.id, label: user.fullName };
					} );
				} )
			;
		},
		async getFreelancersList() {
			this.getFreelancerUsers()
				.then( ( response ) => {
					this.freelancers_list = response.data.users.map( user => {
						return { value: user.id, label: user.fullName };
					} );
				} )
			;
		},
		userHasNotificationsOn( user ) {
			return user.email_notification_projects.length && user.email_notification_projects.find( project => parseInt( project.id ) === parseInt( this.project.id ) );
		},
		openAddUserModal( user_type ) {
			this.active_user_type = user_type;

			if ( user_type === USER_TYPE_CLIENT && !this.clients_list.length ) {
				this.getClientsList();
			} else if ( !this.staff_list.length ) {
				this.getStaffList();
			}

			this.show_user_modal = true;
		},
		closeAddUserModal() {
			this.show_user_modal = false;
			this.active_user_type = null;
		},
		formatUserData( users ) {
			const data = {
				account_director: this.project.account_director.map( user => parseInt( user.id ) ),
				project_manager: this.project.project_manager.map( user => parseInt( user.id ) ),
				lead_artist: this.project.lead_artist.map( user => parseInt( user.id ) ),
				project_artists: this.project.project_artists.map( user => parseInt( user.id ) ),
				clients: this.project.clients.map( user => parseInt( user.id ) )
			};

			switch ( this.active_user_type ) {
				case USER_TYPE_LEAD_ARTIST:
					data['lead_artist'] = users.map( user => parseInt( user.value ) );
					break;
				case USER_TYPE_PROJECT_MANAGER:
					data['project_manager'] = users.map( user => parseInt( user.value ) );
					break;
				case USER_TYPE_ACCOUNT_DIRECTOR:
					data['account_director'] = users.map( user => parseInt( user.value ) );
					break;
				case USER_TYPE_ARTIST:
					data['project_artists'].push( ...users.map( user => parseInt( user.value ) ) );
					break;
				case USER_TYPE_CLIENT:
					data['clients'].push( ...users.map( user => parseInt( user.value ) ) );
					break;
			}

			return data;
		},
		async addUsersToProject( users ) {
			this.errors = {};
			this.error_message = null;
			this.loading_user_update = true;

			let invalid = false;

			users.forEach( user  => {
				if ( !user.value ) {
					this.errors[ user.id ] = 'Please select a user';
					invalid = true;
				}
			} );

			if ( invalid ) {
				this.loading_user_update = false;
				return;
			}

			try {
				const response = await this.saveUsersToProject( {
					project_id: parseInt( this.project.id ),
					...this.formatUserData( users )
				} );
				if ( response && response.data && response.data.save_projects_default_Entry ) {
					this.$emit('update-directory', response.data.save_projects_default_Entry );

					this.loading_user_update = false;
					this.show_user_modal = false;
					this.active_user_type = null;
				}
			} catch ( error ) {
				this.error_message = error;
				this.loading_user_update = false;
			}
		}
	}
};
</script>
