
<template>
	<div
		class="nav-sidebar-wrapper"
		:class="[
			{ 'nav-sidebar-wrapper--hidden': !showNav },
			{ 'nav-sidebar-wrapper--expand': expand_nav }
		]"
	>
		<div
			class="nav-sidebar"
			:class="{ 'nav-sidebar--expand': expand_nav }"
		>
			<div class="nav-sidebar__logo-container">
				<router-link
					:to="home_route"
					class="nav-sidebar__logo"
				/>
				<router-link
					:to="home_route"
					class="nav-sidebar__mini-logo"
				/>
			</div>
			<nav class="nav-sidebar__nav">
				<div class="nav-sidebar__main-nav">
					<ul>
						<li
							v-for="item in filtered_navigation"
							:key="item.route"
						>
							<router-link
								:to="{ name: item.route }"
								class="nav-sidebar__nav-item"
								:class="[
									{ 'nav-sidebar__nav-item--active': isActiveRoute( item.route ) },
								]"
							>
								<div class="nav-sidebar__nav-item-label">
									<span :class="`nav-sidebar__nav-item-icon icon icon--${item.icon}`" />
									<span class="nav-sidebar__nav-item-text">
										{{ item.label }}
									</span>
								</div>
								<span
									v-show="item.route === 'notifications' && all_notifications_pagination.user_related_pending_count"
									class="nav-sidebar__nav-item-notifications"
								>
									{{ all_notifications_pagination.user_related_pending_count }}
								</span>
							</router-link>
						</li>
					</ul>
					<ul>
						<li>
							<button
								class="nav-sidebar__nav-item"
								@click="logoutUser"
							>
								<span class="nav-sidebar__nav-item-icon icon icon--logout--thin" />
								<span class="nav-sidebar__nav-item-text">
									Log out
								</span>
							</button>
						</li>
					</ul>
				</div>
				<div
					v-if="!!user_profile"
					class="nav-sidebar__sub-nav"
				>
					<ProfilePictureComponent
						:user="user_profile"
					/>
					<div class="nav-sidebar__profile-info">
						<p class="nav-sidebar__profile-name">
							{{ user_profile.fullName }}
						</p>
						<p
							v-if="user_profile.user_role"
							class="nav-sidebar__profile-role"
						>
							{{ user_profile.user_role }}
						</p>
					</div>
				</div>
			</nav>
		</div>
		<button
			class="nav-sidebar__toggle"
			@click="toggleNavExpand"
		/>
	</div>
</template>

<script>
import ProfilePictureComponent from '../ProfilePictureComponent.vue';

import { useDataStore } from '../../stores/data';
import { storeToRefs } from 'pinia';
import { USER_GROUP_CLIENT, USER_GROUP_FREELANCER } from '../../constants';

export default {
	components: {
		ProfilePictureComponent
	},
	props: {
		showNav: {
			required: true,
			type: Boolean
		}
	},
	setup() {
		const data_store = useDataStore();
		const {
			logout,
			toggleNavExpand,
			setBreadcrumbs
		} = data_store;
		const {
			expand_nav,
			user_profile,
			all_notifications_pagination,
			user_group
		} = storeToRefs( data_store );

		return {
			expand_nav,
			user_profile,
			user_group,
			all_notifications_pagination,
			logout,
			toggleNavExpand,
			setBreadcrumbs,
		};
	},
	data() {
		return {
			active_routes: [ 'home', 'projects', 'database', 'notifications', 'my-boundary', 'settings' ],
			main_navigation: [
				{
					label: 'Home',
					route: 'home',
					icon: 'home--thin',
					staff_only: true
				},
				{
					label: 'Projects',
					route: 'projects',
					icon: 'briefcase--thin',
					staff_only: false
				},
				{
					label: 'Database',
					route: 'database',
					icon: 'layout-grid--thin',
					staff_only: true
				},
				{
					label: 'Articles',
					route: 'articles',
					icon: 'newspaper--thin',
					staff_only: true
				},
				{
					label: 'Notifications',
					route: 'notifications',
					icon: 'bell--thin',
					staff_only: false
				},
				{
					label: 'My Boundary',
					route: 'my-boundary',
					icon: 'user-outline--thin',
					staff_only: true
				},
				{
					label: 'Support',
					route: 'support',
					icon: 'help--thin',
					staff_only: false
				},
				{
					label: 'Settings',
					route: 'settings',
					icon: 'settings--thin',
					staff_only: false
				},
			]
		};
	},
	computed: {
		home_route() {
			if ( this.user_is_not_staff ) {
				return { name: 'projects' };
			}
			return { name: 'home' };
		},
		filtered_navigation() {
			if ( this.user_is_not_staff ) {
				return this.main_navigation.filter( item => this.active_routes.includes( item.route ) && !item.staff_only );
			}
			return this.main_navigation.filter( item => this.active_routes.includes( item.route ) );
		},
		user_is_not_staff() {
			return this.user_group === USER_GROUP_CLIENT || this.user_group === USER_GROUP_FREELANCER;
		}
	},
	methods: {
		isActiveRoute( route ) {
			if ( !this.$route.name ) {
				return false;
			}
			const base_route = this.$route.name.split( '__' )[0];
			return base_route === route;
		},
		async logoutUser() {
			const response = await this.logout();

			if ( response && response.data && response.data.deleteRefreshTokens ) {
				this.$router.push( { name: 'login' } );
				this.setBreadcrumbs( null );
			}
		}
	}
};

</script>
