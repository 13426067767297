
<template>
	<div
		v-if="active_dropdown_filters.length"
		class="flex flex--wrap flex--gap-medium"
	>
		<p>Filtering by:</p>
		<button
			v-for="status in project_status.active"
			:key="'active-status-' + status.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveProjectStatus( status )"
		>
			{{ status.label }}
		</button>
		<button
			v-for="location in project_location.active"
			:key="'active-location-' + location.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( location, 'project_location' )"
		>
			{{ location.label }}
		</button>
		<button
			v-for="deliverable in deliverables.active"
			:key="'active-deliverable-' + deliverable.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( deliverable, 'deliverables' )"
		>
			{{ deliverable.label }}
		</button>
		<button
			v-for="company in client.active"
			:key="'active-client-' + company.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( company, 'client' )"
		>
			{{ company.label }}
		</button>
		<button
			v-for="user in project_manager.active"
			:key="'active-project-manager-' + user.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( user, 'project_manager' )"
		>
			{{ user.label }}
		</button>
		<button
			v-for="user in account_director.active"
			:key="'active-account-director-' + user.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( user, 'account_director' )"
		>
			{{ user.label }}
		</button>
		<button
			v-for="user in lead_artist.active"
			:key="'active-project-director-' + user.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( user, 'lead_artist' )"
		>
			{{ user.label }}
		</button>
		<button
			v-for="user in business_development.active"
			:key="'active-project-director-' + user.value"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveActiveFilterValue( user, 'business_development' )"
		>
			{{ user.label }}
		</button>
		<button
			v-if="active_dropdown_filters.length > 1"
			class="kappa icon-end icon-end--close"
			@click="handleRemoveAllFilters"
		>
			Clear all
		</button>
	</div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useProjectFiltersStore } from '../../stores/project-filters';

const filters_store = useProjectFiltersStore();

const {
	removeAllActiveDropdownFilters,
	removeActiveProjectStatus,
	removeActiveFilterValue,
	resetProjectPagination,
	updateQueryStringFromProjectFilters
} = filters_store;

const {
	project_status,
	project_location,
	deliverables,
	client,
	project_manager,
	account_director,
	lead_artist,
	business_development,
	active_dropdown_filters
} = storeToRefs( filters_store );

const handleRemoveActiveProjectStatus = ( status ) => {
	removeActiveProjectStatus( status );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};
const handleRemoveActiveFilterValue = ( value, handle ) => {
	removeActiveFilterValue( value, handle );
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
}
const handleRemoveAllFilters = () => {
	removeAllActiveDropdownFilters();
	resetProjectPagination();
	updateQueryStringFromProjectFilters();
};
</script>