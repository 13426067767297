
<template>
	<div class="flex flex--justify-space-between flex--align-center flex--gap-extra-large mb-16">
		<ProjectSearchFilterComponent />

		<ProjectSortingFilterComponent
			v-if="active_projects_tab === 'all'"
		/>
	</div>

	<Transition
		v-if="user_is_staff"
		name="fade"
	>
		<div
			v-show="active_projects_tab !== 'example_projects' && show_filters"
			class="project-filters mb-16"
		>
			<div class="project-filters__row">
				<div class="project-filters__column project-filters__column--space-between">
					<div
						v-if="selected_dropdown_filter_components.length"
						class="grid grid--stretch grid--xs flex flex--wrap full-width mb-16"
					>
						<div
							v-for="filter in selected_dropdown_filter_components"
							:key="'dropdown-filter-component-' + filter.value"
							class="grid__item a4-12 mb-8"
						>
							<component :is="filter.component" />
						</div>
					</div>
					<p v-else>
						No filters selected
					</p>

					<button
						class="button mb-16"
						@click="show_filter_modal = true"
					>
						Choose Filters
					</button>

					<Teleport to="#modal">
						<Transition name="fade">
							<FilterModalComponent
								v-if="show_filter_modal"
								@close-modal="show_filter_modal = false"
							/>
						</Transition>
					</Teleport>

					<ActiveDropdownFiltersComponent />
				</div>
				<div class="project-filters__column">
					<ProjectTagsFilterComponent />
				</div>
			</div>
		</div>
	</Transition>
</template>

<script setup>
import ActiveDropdownFiltersComponent from './project-filters/ActiveDropdownFiltersComponent';
import FilterModalComponent from './modals/FilterModalComponent';
import ProjectTagsFilterComponent from './project-filters/ProjectTagsFilterComponent';
import ProjectSearchFilterComponent from './project-filters/ProjectSearchFilterComponent';
import ProjectSortingFilterComponent from './project-filters/ProjectSortingFilterComponent';

import { ref, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useDataStore } from '../stores/data';
import { useProjectFiltersStore } from '../stores/project-filters';
import { USER_GROUP_STAFF } from '../constants';

const data_store = useDataStore();
const filters_store = useProjectFiltersStore();

const { user_group } = storeToRefs( data_store );

const {
	getBoundaryStaffUsers,
	getCompanies,
	getDeliverables
} = filters_store;

const {
	active_projects_tab,
	show_filters,
	selected_dropdown_filter_components
} = storeToRefs( filters_store );

const show_filter_modal = ref( false );

const user_is_staff = computed( () => {
	return user_group.value === USER_GROUP_STAFF;
} );

onMounted ( async () => {
	await getBoundaryStaffUsers();
	await getCompanies();
	await getDeliverables();
} );
</script>