
<template>
	<div class="container">
		<Transition name="fade">
			<PageLoaderComponent v-if="loading" />
			<div v-else>
				<div class="mb-32">
					<h1 class="h1">
						Projects
					</h1>
				</div>

				<TabNavigationComponent
					:tabs="filtered_tabs"
					:active-tab="active_projects_tab"
					:disabled="loading_projects"
					@set-tab="setTab"
				/>

				<ProjectFiltersComponent />

				<ProjectGridComponent
					:projects="projects"
					:loading-projects="loading_projects"
				/>

				<div class="flex flex--justify-center">
					<PageLoaderComponent
						v-if="projects.length && projects.length < project_pagination.total_count"
						ref="load_more_trigger"
						:small="true"
					/>
				</div>
			</div>
		</Transition>
	</div>
</template>

<script setup>
import ProjectGridComponent from '../components/ProjectGridComponent';
import ProjectFiltersComponent from '../components/ProjectFiltersComponent';
import PageLoaderComponent from '../components/PageLoaderComponent';
import TabNavigationComponent from '../components/TabNavigationComponent';

import { ref, computed, nextTick, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useDataStore } from '../stores/data';
import { useProjectFiltersStore } from '../stores/project-filters';
import { useElementVisibility } from '@vueuse/core';
import { isEqual } from 'underscore';
import { filterOutDuplicates } from '../../helpers';
import { USER_GROUP_STAFF } from '../constants';

const data_store = useDataStore();
const filters_store = useProjectFiltersStore();
const route = useRoute();

const {
	getProjects,
	getUserRelatedProjects,
	getExampleProjects,
	getPendingNotificationsCount,
	setBreadcrumbs,
} = data_store;

const {
	user_group
} = storeToRefs( data_store );

const {
	setProjectSearchQuery,
	setActiveProjectsTab,
	setActiveProjectStatuses,
	getActiveProjectTags,
	setActiveProjectTags,
	getActiveProjectLocations,
	getActiveDeliverables,
	setActiveFilterValues,
	getActiveProjectManagers,
	getActiveAccountDirectors,
	getActiveLeadArtists,
	getActiveBusinessDevelopers,
	getActiveClients,
	setShowFilters,
	resetProjectPagination,
	incrementProjectPagination,
	updateProjectTotalCount,
	setProjectSorting,
	updateQueryStringFromProjectFilters,
	resetActiveAdvancedFilters,
	selectMissingActiveDropdowns
} = filters_store;

const {
	active_projects_tab,
	show_filters,
	active_dropdown_filters,
	project_pagination,
	project_sorting,
	project_status,
	deliverables,
	project_search_query,
	project_tags,
	project_location,
	client,
	project_manager,
	account_director,
	lead_artist,
	business_development,
} = storeToRefs( filters_store );

const user_is_staff = computed( () => {
	return user_group.value === USER_GROUP_STAFF;
} );

// Tabs
const setTab = async ( slug ) => {
	setActiveProjectsTab( slug );
	resetProjectPagination();
	await nextTick();
	updateQueryStringFromProjectFilters();
};

const filtered_tabs = computed( () => {
	if ( !user_is_staff.value ) {
		return [
			{
				slug: 'all',
				label: 'My Projects'
			},
			{
				slug: 'example_projects',
				label: 'Example Projects'
			}
		];
	}
	return [
		{
			slug: 'all',
			label: 'All projects'
		},
		{
			slug: 'my_projects',
			label: 'My Projects'
		},
		{
			slug: 'example_projects',
			label: 'Example Projects'
		}
	];
} );

// Projects
const loading = ref( true );
const loading_projects = ref( false );
const projects = ref( [] );


const fetchProjects = async () => {
	loading_projects.value = true;

	try {
		let response;
		let params = {
			limit: project_pagination.value.limit,
			search: project_search_query.value,
			offset: ( project_pagination.value.page - 1 ) * project_pagination.value.limit
		};

		if ( active_projects_tab.value === 'my_projects' ) {
			response = await getUserRelatedProjects( params );

		} else if ( active_projects_tab.value === 'example_projects' ) {
			response = await getExampleProjects();

		} else {
			params.order_by = getSortOrder();
			params.tags = getRelatedTagsArray();

			if ( project_location.value.active.length ) {
				params.location = project_location.value.active.map( location => parseInt( location.value ) );
			}
			if ( deliverables.value.active.length ) {
				params.deliverable = deliverables.value.active.map( deliverable => parseInt( deliverable.value ) );
			}
			if ( project_status.value.active.length ) {
				params.project_status = project_status.value.active.map( status => status.value );
			}
			if ( client.value.active.length ) {
				params.client = client.value.active.map( company => parseInt( company.value ) );
			}
			if ( project_manager.value.active.length ) {
				params.project_manager = project_manager.value.active.map( user => parseInt( user.value ) );
			}
			if ( account_director.value.active.length ) {
				params.account_director = account_director.value.active.map( user => parseInt( user.value ) );
			}
			if ( lead_artist.value.active.length ) {
				params.lead_artist = lead_artist.value.active.map( user => parseInt( user.value ) );
			}
			if ( business_development.value.active.length ) {
				params.business_development = business_development.value.active.map( user => parseInt( user.value ) );
			}


			response = await getProjects( params );
		}

		if ( project_pagination.value.page === 1 ) {
			projects.value = [];
		}

		addProjectsToArray( response.data.entries || response.data.projects || response.data.globalSet.example_projects );

		let total_project_count;
		if ( active_projects_tab.value === 'example_projects' ) {
			total_project_count = projects.value.length;
		} else {
			total_project_count = response.data.total_count;
		}

		updateProjectTotalCount( total_project_count );
	} catch ( error ) {
		// console.log( error );
	}

	fetchNotificationCounts();
	loading_projects.value = false;
};

const fetchMoreProjects = async () => {
	incrementProjectPagination();
	await nextTick();
	fetchProjects();
};

const addProjectsToArray = ( projects_to_add ) => {
	projects.value.push( ...projects_to_add );
	projects.value = filterOutDuplicates( projects.value );
};

const fetchNotificationCounts = async () => {
	for ( let i = 0; i < projects.value.length; i++ ) {
		try {
			const count = await getPendingNotificationsCount( {
				project_ids: [ parseInt( projects[i].id ) ]
			} );
			projects.value[i].notification_count = count;
		} catch ( error ) {
			// console.log( error );
		}
	}
};

const updateResultsFromQueryString = async () => {
	const query = route.query;

	if ( query.tab ) {
		const tab = !user_is_staff.value && query.tab === 'my_projects' ? 'all' : query.tab;
		setActiveProjectsTab( tab );
	} else {
		setActiveProjectsTab( 'all' );
	}

	setProjectSearchQuery( query && query.search ? query.search : '' );

	if ( !user_is_staff.value || active_projects_tab.value !== 'all' ) {
		// Wipe staff-only filters and fetch data
		resetActiveAdvancedFilters();
		if ( query.tag ) {
			updateQueryStringFromProjectFilters();
		}
		return;
	}

	let activated_dropdowns = [];

	if ( query.tag ) {
		const param_array = Array.isArray( query.tag ) ? query.tag : [query.tag];
		if ( param_array.map( tag_id => parseInt( tag_id ) ).sort() !== [...project_tags.value.active].map( tag => parseInt( tag.id ) ).sort() ) {
			await getActiveProjectTags( query.tag );
		}
	} else {
		setActiveProjectTags( [] );
	}
	if ( query.status ) {
		const param_array = Array.isArray( query.status ) ? query.status : [query.status];
		if ( param_array.sort() !== [...project_status.value.active].map( status => status.value ).sort() ) {
			setActiveProjectStatuses( param_array );
			activated_dropdowns.push( 'project_status' );
		}
	} else {
		setActiveProjectStatuses( [] );
	}
	if ( query.location ) {
		const param_array = Array.isArray( query.location ) ? query.location : [query.location];
		if ( param_array.map( location_id => parseInt( location_id ) ).sort() !== [...project_location.value.active].map( location => parseInt( location.value ) ).sort() ) {
			await getActiveProjectLocations( param_array );
			activated_dropdowns.push( 'project_location' );
		}
	} else {
		setActiveFilterValues( [], 'project_location' );
	}
	if ( query.deliverable ) {
		const param_array = Array.isArray( query.deliverable ) ? query.deliverable : [query.deliverable];
		if ( param_array.map( media_type_id => parseInt( media_type_id ) ).sort() !== [...deliverables.value.active].map( deliverable => parseInt( deliverable.value ) ).sort() ) {
			await getActiveDeliverables( param_array );
			activated_dropdowns.push( 'deliverables' );
		}
	} else {
		setActiveFilterValues( [], 'project_location' );
	}
	if ( query.client ) {
		const param_array = Array.isArray( query.client ) ? query.client : [query.client];
		if ( param_array.map( company_id => parseInt( company_id ) ).sort() !== [...client.value.active].map( company => parseInt( company.value ) ).sort() ) {
			await getActiveClients( param_array );
			activated_dropdowns.push( 'client' );
		}
	} else {
		setActiveFilterValues( [], 'client' );
	}
	if ( query.project_manager ) {
		const param_array = Array.isArray( query.project_manager ) ? query.project_manager : [query.project_manager];
		if ( param_array.map( user_id => parseInt( user_id ) ).sort() !== [...project_manager.value.active].map( user => parseInt( user.value ) ).sort() ) {
			await getActiveProjectManagers( param_array );
			activated_dropdowns.push( 'project_manager' );
		}
	} else {
		setActiveFilterValues( [], 'project_manager' );
	}
	if ( query.account_director ) {
		const param_array = Array.isArray( query.account_director ) ? query.account_director : [query.account_director];
		if ( param_array.map( user_id => parseInt( user_id ) ).sort() !== [...account_director.value.active].map( user => parseInt( user.value ) ).sort() ) {
			await getActiveAccountDirectors( param_array );
			activated_dropdowns.push( 'account_director' );
		}
	} else {
		setActiveFilterValues( [], 'account_director' );
	}
	if ( query.lead_artist ) {
		const param_array = Array.isArray( query.lead_artist ) ? query.lead_artist : [query.lead_artist];
		if ( param_array.map( user_id => parseInt( user_id ) ).sort() !== [...lead_artist.value.active].map( user => parseInt( user.value ) ).sort() ) {
			await getActiveLeadArtists( param_array );
			activated_dropdowns.push( 'lead_artist' );
		}
	} else {
		setActiveFilterValues( [], 'lead_artist' );
	}
	if ( query.business_development ) {
		const param_array = Array.isArray( query.business_development ) ? query.business_development : [query.business_development];
		if ( param_array.map( user_id => parseInt( user_id ) ).sort() !== [...business_development.value.active].map( user => parseInt( user.value ) ).sort() ) {
			await getActiveBusinessDevelopers( param_array );
			activated_dropdowns.push( 'business_development' );
		}
	} else {
		setActiveFilterValues( [], 'business_development' );
	}
	if ( query.sort ) {
		if ( query.sort === 'title' ) {
			setProjectSorting( {
				selected_sort_order: 'title',
				title_sort_order: query.order ?? 'DESC',
				date_sort_order: 'DESC'
			} );
		} else {
			setProjectSorting( {
				selected_sort_order: 'date',
				title_sort_order: 'DESC',
				date_sort_order: query.order ?? 'DESC'
			} );
		}
	} else {
		setProjectSorting( {
			selected_sort_order: 'title',
			title_sort_order: 'DESC',
			date_sort_order: 'DESC'
		} );
	}

	if ( activated_dropdowns.length ) {
		selectMissingActiveDropdowns( activated_dropdowns );
	}

	if ( !show_filters.value && ( project_tags.value.active.length || active_dropdown_filters.value.length ) ) {
		setShowFilters( true );
	}
};

const getSortOrder = () => {
	if ( project_sorting.value.selected_sort_order === 'date' ) {
		return `last_updated ${project_sorting.value.date_sort_order}`;
	}
	return `title ${project_sorting.value.title_sort_order}`;
};

const getRelatedTagsArray = () => {
	if ( !project_tags.value.active.length ) {
		return [];
	}
	return [ 'and', ...project_tags.value.active.map( tag => parseInt( tag.id ) ) ];
};

const load_more_trigger = ref( null );
const trigger_is_visible = useElementVisibility( load_more_trigger );

watch( trigger_is_visible, async ( new_value, old_value ) => {
	if ( new_value && !old_value && !loading_projects.value ) {
		await fetchMoreProjects();
	}
} );

// Watch for query change to refetch data
const route_query = computed( () => route.query );

watch( route_query, async ( new_query, old_query ) => {
	if ( isEqual( new_query, old_query ) ) {
		return;
	}

	await updateResultsFromQueryString();
	await nextTick();
	await fetchProjects();
} );

// Mount
onMounted( async () => {
	loading.value = true;
	if ( !user_is_staff.value ) {
		setActiveProjectsTab( 'all' );
	}

	setBreadcrumbs( [ { label: 'Projects', icon: 'briefcase' } ] );
	await updateResultsFromQueryString();
	await nextTick();
	await fetchProjects();

	loading.value = false;
} );
</script>